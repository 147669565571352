// src/PowerBIReport.js
import React, { useState, useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

const PowerBIReport = ({ embedConfig, pageName }) => {
  console.log('PowerBIReport;', embedConfig)
  const [report, setReport] = useState(null);

  useEffect(() => {
    if (report) {
      report.on('loaded', function () {
        console.log('Report loaded');
        // Navigate to the specific page
        report.setPage(pageName).catch(function (error) {
          console.error('Error setting page:', error);
        });
      });
    }
  }, [report, pageName]);

  return (
    <PowerBIEmbed
      embedConfig={embedConfig}
      eventHandlers={
        new Map([
          ['loaded', function () { console.log('Report loaded'); }],
          ['rendered', function () { console.log('Report rendered'); }],
          ['error', function (event) { console.log(event.detail); }],
          ['visualClicked', () => console.log('Visual clicked')],
          ['pageChanged', (event) => console.log(event)],
        ])
      }
      cssClassName={"report-style-class"}
      getEmbeddedComponent={(embeddedReport) => {
        window.report = embeddedReport;
        console.log("getEmbeddedComponent", embeddedReport);
        setReport(embeddedReport);
      }}
    />
  );
};

export default PowerBIReport;
