

import React from 'react'
import Header from "../Auth/Header/header"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useState } from 'react';
import PageLoader from "../../Components/services/pageloader";
import { getAccessToken, getToken, getServerStatus, upgrateServerTime, suspendServerTime, azureAccessToken, getEmbededUrlClaims, getEmbededUrlDashboard } from "../../Components/services/api-servics";
import { useEffect } from 'react';
// import React, { useState, useEffect } from 'react';
import PowerBIReport from './powerBIReport';
export default function Dashboard() {
  const [value, setValue] = useState('one');
  const [accessToken, setGetToken] = useState(undefined);
  const [accessTokenClaims, setGetTokenClaims] = useState(undefined);
  const [embededUrl, setEmbededUrl] = useState(undefined);
  const [embededUrlClaims, setEmbededUrlClaims] = useState(undefined);
  const [serverToken, setServerToken] = useState(undefined);
  const [serverState, setServerState] = useState("");
  const [pageLoad, setPageLoad] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const MINUTE_MS = 60000;
  const [report, setReport] = useState(null);
  const [embedConfig, setEmbedConfig] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    retreivePowerBiCredentialsDashboard();
  }, [])
  const retreivePowerBiCredentialsDashboard = async () => {
    try {
      setPageLoad(true);
      await getToken().then(async (response) => {
        console.log(response, "resss");
        if (
          response !== "" &&
          response !== undefined &&
          response !== null
        ) {
          if (response.data !== undefined && response.data !== null && response.data !== "") {
            let AccessToken = "Bearer " + response.data.access_token


            let data = {
              "datasets": [
                {
                  "id": process.env.REACT_APP_DATASETID_DASHBOARD
                }
              ],
              "reports": [
                {
                  "id": process.env.REACT_APP_REPORT_ID_DASHBOARD
                }
              ],

            }

            await getAccessToken(data, AccessToken).then(async (response) => {
              if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.status == 200

              ) {
                if (response.data !== undefined && response.data !== "") {
                  setGetToken(response.data.token)
                }
              } else {

              }

            });
            await getEmbededUrlDashboard(AccessToken).then((response) => {
              if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.status == 200

              ) {
                if (response.data !== undefined && response.data !== "") {
                  setPageLoad(false)
                  setEmbededUrl(response.data.embedUrl)
                }
              } else {
              }

            });
          }

        } else {
          setPageLoad(false)
        }

      });
    } catch (e) {
      setPageLoad(false)
    }
  }
  useEffect(() => {
    if (value == 'two') {

      retreivePowerBiCredentialsClaims();
    } else {

      retreivePowerBiCredentialsDashboard();
    }
  }, [value])

  const retreivePowerBiCredentialsClaims = async () => {
    try {
      setPageLoad(true);
      await getToken().then(async (response) => {
        console.log(response, "resss");
        if (
          response !== "" &&
          response !== undefined &&
          response !== null
        ) {
          if (response.data !== undefined && response.data !== null && response.data !== "") {
            let AccessToken = "Bearer " + response.data.access_token


            let data = {
              "datasets": [
                {
                  "id": process.env.REACT_APP_DATASETID_CLAIMS
                }
              ],
              "reports": [
                {
                  "id": process.env.REACT_APP_REPORT_ID_CLAIMS
                }
              ],

            }

            await getAccessToken(data, AccessToken).then(async (response) => {
              console.log("getAccessToken", response)
              if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.status == 200

              ) {
                if (response.data !== undefined && response.data !== "") {
                  setGetTokenClaims(response.data.token)
                }
              } else {

              }

            });
            await getEmbededUrlClaims(AccessToken).then((response) => {
              if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.status == 200

              ) {
                console.log("getEmbededUrlClaims", response)
                if (response.data !== undefined && response.data !== "") {
                  setEmbededUrlClaims(response.data.embedUrl)
                  setPageLoad(false)

                }
              } else {
                setPageLoad(false)
              }

            });
          }

        } else {
          setPageLoad(false)
        }

      });
    } catch (e) {
      setPageLoad(false)
    }
  }

  useEffect(() => {
    console.log("fetchEmbedConfig")
    // Fetch the embed token and other details from your backend or environment variables
    const fetchEmbedConfig = async () => {
      const embedConfig = {
        type: 'report',
        id: process.env.REACT_APP_REPORT_ID_CLAIMS, // Replace with your report ID
        embedUrl: embededUrlClaims, // Replace with your embed URL
        accessToken: accessTokenClaims, // Replace with your embed token
        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            }
          },
          background: models.BackgroundType.Transparent,
        }
      };
      setEmbedConfig(embedConfig);
    };
    fetchEmbedConfig();
  }, [embededUrlClaims]);
  return (
    <div>

      <PageLoader showLoader={pageLoad} />
      <Header />
      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Box sx={{ width: '100%', marginLeft: "15px" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                  >
                    <Tab value="one" label="Dashboard" />
                    <Tab value="two" label="Claims" />

                  </Tabs>


                </Box>
                {value == "one" ?

                  <div className="col-12 w-100 h-20">
                    <PowerBIEmbed
                      embedConfig={{
                        type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                        id: process.env.REACT_APP_REPORT_ID_DASHBOARD,
                        embedUrl: embededUrl,
                        accessToken: accessToken,
                        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                        settings: {
                          panes: {
                            filters: {
                              expanded: false,
                              visible: false,
                            }
                          },
                          background: models.BackgroundType.Transparent,
                        }
                      }}

                      eventHandlers={
                        new Map([
                          ['loaded', function () { console.log('Report loaded'); }],
                          ['rendered', function () { console.log('Report rendered'); }],
                          ['error', function (event) { console.log(event.detail) }],
                          ['visualClicked', () => console.log('visual clicked')],
                          ['pageChanged', (event) => console.log(event)],
                        ])
                      }
                      cssClassName={"report-style-class"}
                      getEmbeddedComponent={
                        (embeddedReport) => {
                          window.report = embeddedReport;
                          console.log("getEmbeddedComponent", report);
                          setReport(embeddedReport)

                        }
                      }

                    />
                  </div>
                  :
                  // pageLoad ? <PageLoader /> :


                    <div className="col-12 w-100">

                      <PowerBIReport
                        embedConfig={embedConfig}
                        pageName="ReportSection" // Replace with your page name
                        displayName="Claims" // Display name handling can be done within the report component if needed
                      />

                    </div>
                }

              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}
